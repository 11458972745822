import { render, staticRenderFns } from "./passport-icon.vue?vue&type=template&id=86a9db28"
import script from "./passport-icon.vue?vue&type=script&lang=js"
export * from "./passport-icon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports